<template>
    <div>
        <header>
            <v-container>
                <v-row no-gutters>
                    <h1 class="font-weight-light headline">
                        {{ $t("title.clients") }}
                    </h1>
                    <v-spacer></v-spacer>
                    <v-lazy v-if="isClientsLoaded" transition="fade-transition">
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            :label="searchLabel"
                            single-line
                            dense
                            hide-details
                            style="max-width: 200px"
                            class="mr-5 pr-5"
                        ></v-text-field>
                    </v-lazy>
                    <v-lazy transition="fade-transition">
                        <v-btn
                            color="success"
                            dark
                            small
                            @click.stop="
                                dialog.clientForm = true;
                                isClientEdited = false;
                            "
                            >{{ $t("general.add") }}</v-btn
                        >
                    </v-lazy>
                </v-row>
            </v-container>
        </header>
        <section class="py-7">
            <v-container>
                <v-row v-if="isClientsLoaded && !clients.length">
                    <v-col cols="12" md="4" class="offset-md-4">
                        <v-alert
                            icon="mdi-information-outline"
                            type="info"
                            text
                            border="top"
                        >
                            {{ $t("general.we_dont_have_data_yet") }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-sheet class="px-3 pt-3 pb-3" v-if="!clients.length && !isClientsLoaded">
                    <v-skeleton-loader
                        class="mx-auto"
                        type="table"
                    ></v-skeleton-loader>
                </v-sheet>
                <v-card v-else>
                    <v-data-table
                        v-if="clients.length"
                        :headers="headers"
                        :items="clients"
                        :search="search"
                    >
                        <template v-slot:item.action="{ item }">
                            <div style="display: flex">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            v-tr-class-on-hover="'blue'"
                                            size="20px"
                                            color="blue"
                                            class="mr-2"
                                            @click="editUser(item)"
                                            v-on="on"
                                            >mdi-pencil-outline</v-icon
                                        >
                                    </template>
                                    <span>{{ $t("general.edit") }}</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            size="22px"
                                            color="red"
                                            v-tr-class-on-hover="'red'"
                                            class="mr-2"
                                            @click="confirmDelete(item)"
                                            v-on="on"
                                            >mdi-delete-outline</v-icon
                                        >
                                    </template>
                                    <span>{{ $t("general.delete") }}</span>
                                </v-tooltip>
                            </div>
                        </template>
                        <template v-slot:item.is_active="{ item }">
                            <v-switch
                                class="mt-0 pt-0 justify-content-center"
                                hide-details
                                color="green"
                                v-model="item.is_active"
                                @change="changeStatus(item)"
                            ></v-switch>
                        </template>
                        <template v-slot:item.company="{ item }">
                            <span v-if="item.company == null">-</span>
                            <span v-else>{{ item.company.name }}</span>
                        </template>
                        <template v-slot:item.internal_id="{ item }">
                            <span v-if="item.internal_id == null">-</span>
                            <span v-else>{{ item.internal_id }}</span>
                        </template>
                        <template v-slot:item.division="{ item }">
                            <span v-if="item.division == null">-</span>
                            <span v-else>{{ item.division.name }}</span>
                        </template>
                        <template v-slot:item.section="{ item }">
                            <span v-if="item.section == null">-</span>
                            <span v-else>{{ item.section.name }}</span>
                        </template>
                    </v-data-table>
                </v-card>
                <ClientForm
                    :client="selectedClient"
                    :visible="dialog.clientForm"
                    :isEdited="isClientEdited"
                    @addClient="addClient"
                    @updateClient="updateClient"
                    @close="dialog.clientForm = false"
                    @snackMessage="snackMessage"
                />

                <ConfirmationDialog
                    :name="selectedClient.name"
                    :text="'clients.delete_confirmation'"
                    v-if="dialog.confirmation"
                    @confirm="deleteClient"
                    @cancel="cancelDelete"
                />

                <SnackMessage ref="SnackMessage" />
            </v-container>
        </section>
    </div>
</template>

<script>
import ClientForm from "../components/ClientForm";
import ConfirmationDialog from "../components/ConfirmationDialog";
import SnackMessage from "../components/SnackMessage";

export default {
    data() {
        return {
            clients: [],
            isClientsLoaded: false,
            search: "",
            dialog: {
                confirmation: false,
                clientForm: false,
            },
            client: {
                name: null,
                company_id: null,
                internal_id: null,
                division_id:null,
                section_id:null,
                delivery_note_2: null,
                delivery_note: null,
                is_active: null
            },
            selectedClient: {
                name: null,
                company_id: null,
                internal_id: null,
                division_id:null,
                section_id:null,
                delivery_note_2: null,
                delivery_note: null,
                is_active: null
            },
            defaultClient: {
                name: null,
                company_id: null,
                internal_id: null,
                division_id:null,
                section_id:null,
                delivery_note_2: null,
                delivery_note: null,
                is_active: null
            },
            isClientEdited: false
        };
    },

    components: {
        ClientForm,
        ConfirmationDialog,
        SnackMessage,
    },

    computed: {
        headers() {
            return [
                {
                    text: this.$t("general.actions"),
                    value: "action",
                    sortable: false,
                },
                {
                    text: this.$t("clients.name"),
                    align: "left",
                    sortable: false,
                    value: "name",
                },
                {
                    text: this.$t("clients.company"),
                    align: "left",
                    sortable: false,
                    value: "company",
                },
                {
                    text: this.$t("clients.internal_id_table"),
                    align: "left",
                    sortable: false,
                    value: "internal_id",
                },
                {
                    text: this.$t("clients.division"),
                    align: "left",
                    sortable: false,
                    value: "division",
                },
                {
                    text: this.$t("clients.section"),
                    align: "left",
                    sortable: false,
                    value: "section",
                },
                {
                    text: this.$t("clients.is_active"),
                    align: "left",
                    sortable: false,
                    value: "is_active",
                },
                {
                    text: this.$t("general.created_at"),
                    align: "left",
                    sortable: false,
                    value: "created_at",
                },
            ];
        },
        searchLabel() {
            return this.$t("general.search");
        },
    },

    created() {
        this.getClients();
    },

    methods: {
        snackMessage(msg, type) {
            this.$refs.SnackMessage.showMessage(msg, type);
        },

        async getClients() {
            await axios
                .get("/api/clientList", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token,
                    },
                })
                .then((response) => {
                    this.clients = response.data;
                    this.isClientsLoaded = true;
                })
                .catch((error) => {
                    console.log(error.response.data);
                });
        },
        addClient(client) {
            this.clients.push(client);
            this.getClients()
        },
        editUser(item) {
            this.selectedClient = Object.assign({}, item);
            this.dialog.clientForm = true;
            this.isClientEdited = true;
        },
        updateClient(client) {
            let index = this.clients.findIndex((item) => item.id == client.id);
            this.clients.splice(index, 1, client);
            this.getClients()
        },
        confirmDelete(item) {
            this.selectedClient = item;
            this.dialog.confirmation = true;
        },
        cancelDelete() {
            this.dialog.confirmation = false;
        },
        async deleteClient() {
            this.dialog.confirmation = false;
            let index = this.clients.indexOf(this.selectedClient);

            await axios
                .delete(`/api/deleteClient/${this.selectedClient.id}`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token,
                    },
                })
                .then((response) => {
                    this.selectedClient = this.defaultClient;
                    this.clients.splice(index, 1);

                    this.$refs.SnackMessage.showMessage(
                        "clients.deleted_successfully",
                        "success"
                    );
                })
                .catch((error) => {
                    console.log(error);
                    this.$refs.SnackMessage.showMessage(
                        "general.error",
                        "error"
                    );
                });
        },
        async changeStatus(item) {
            try {
                const request = await axios.put(
                    "/api/updateStatusClient/" + item.id,
                    item,
                    {
                        headers: {
                            Authorization:
                                "Bearer " +
                                this.$store.state.tokenData.user.access_token,
                        },
                    }
                );
                this.getClients()
                this.snackMessage("general.item_saved", "success");
            } catch (error) {
                console.error(error);
            }
        },
    },
};
</script>
