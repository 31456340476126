var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"app-content"}},[_c('v-navigation-drawer',{attrs:{"mini-variant":_vm.primaryDrawer.mini,"app":"","clipped":""}},[_c('v-list',[(_vm.$store.state.profile.role === 'admin')?_c('router-link',{staticClass:"d-block text-decoration-none",attrs:{"to":{ name: 'trainings' }}},[(_vm.primaryDrawer.mini)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('div',{staticClass:"ap-list-button"},[_c('v-icon',[_vm._v("mdi-school")])],1)])]}}],null,false,1158879413)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("title.trainings")))])]):_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-school")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("title.trainings")))])],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.$store.state.profile.role === 'admin')?_c('label',{staticClass:"py-2 pl-4 d-block itb-drawer-label mt-3"},[(_vm.primaryDrawer.mini)?_c('v-icon',[_vm._v("mdi-poll")]):_c('span',[_vm._v(_vm._s(_vm.$t("title.analytics")))])],1):_vm._e(),_vm._v(" "),(_vm.$store.state.profile.role === 'admin')?_c('router-link',{staticClass:"d-block text-decoration-none",attrs:{"to":{ name: 'results' }}},[(_vm.primaryDrawer.mini)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('div',{staticClass:"ap-list-button"},[_c('v-icon',[_vm._v("mdi-list-status")])],1)])]}}],null,false,3028211962)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("title.results")))])]):_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-list-status")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("title.results")))])],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.$store.state.profile.role === 'admin')?_c('label',{staticClass:"py-2 pl-4 d-block itb-drawer-label mt-3"},[(_vm.primaryDrawer.mini)?_c('v-icon',[_vm._v("mdi-cog-outline")]):_c('span',[_vm._v(_vm._s(_vm.$t("title.settings")))])],1):_vm._e(),_vm._v(" "),(_vm.$store.state.profile.role === 'admin')?_c('router-link',{staticClass:"d-block text-decoration-none",attrs:{"to":{ name: 'oculus-devices' }}},[(_vm.primaryDrawer.mini)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('div',{staticClass:"ap-list-button"},[_c('v-icon',[_vm._v("mdi-safety-goggles")])],1)])]}}],null,false,4037593154)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("title.oculus_devices")))])]):_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-safety-goggles")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("title.oculus_devices")))])],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.$store.state.profile.role === 'admin')?_c('router-link',{staticClass:"d-block text-decoration-none",attrs:{"to":{ name: 'clients' }}},[(_vm.primaryDrawer.mini)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('div',{staticClass:"ap-list-button"},[_c('v-icon',[_vm._v("mdi-account-hard-hat")])],1)])]}}],null,false,1377753474)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("title.clients")))])]):_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-account-hard-hat")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("title.clients")))])],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.$store.state.profile.role === 'admin')?_c('router-link',{staticClass:"d-block text-decoration-none",attrs:{"to":{ name: 'users' }}},[(_vm.primaryDrawer.mini)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('div',{staticClass:"ap-list-button"},[_c('v-icon',[_vm._v("mdi-account-multiple")])],1)])]}}],null,false,688950813)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("title.users")))])]):_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-account-multiple")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("title.users")))])],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.$store.state.profile.role === 'admin')?_c('router-link',{staticClass:"d-block text-decoration-none",attrs:{"to":{ name: 'companies' }}},[(_vm.primaryDrawer.mini)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('div',{staticClass:"ap-list-button"},[_c('v-icon',[_vm._v("mdi-factory")])],1)])]}}],null,false,1955341301)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("title.companies")))])]):_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-factory")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("title.companies")))])],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.$store.state.profile.role === 'admin')?_c('router-link',{staticClass:"d-block text-decoration-none",attrs:{"to":{ name: 'divisions' }}},[(_vm.primaryDrawer.mini)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('div',{staticClass:"ap-list-button"},[_c('v-icon',[_vm._v("mdi-file-tree")])],1)])]}}],null,false,2393936780)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("title.divisions")))])]):_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-file-tree")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("title.divisions")))])],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.$store.state.profile.role === 'admin')?_c('router-link',{staticClass:"d-block text-decoration-none",attrs:{"to":{ name: 'sections' }}},[(_vm.primaryDrawer.mini)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('div',{staticClass:"ap-list-button"},[_c('v-icon',[_vm._v("mdi-badge-account-horizontal-outline")])],1)])]}}],null,false,713134614)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("title.sections")))])]):_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-badge-account-horizontal-outline")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("title.sections")))])],1)],1)],1):_vm._e()],1)],1),_vm._v(" "),_c('v-app-bar',{attrs:{"app":"","clipped-left":"","dark":"","color":"primary"}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.primaryDrawer.mini = !_vm.primaryDrawer.mini}}}),_vm._v(" "),_c('v-toolbar-title',[_c('img',{attrs:{"align":"middle","src":"/images/vrb_h_white@1x.png","height":"25"}})]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),(_vm.$store.state.profile.role === 'admin')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 itb-icon-tooltip",attrs:{"color":"yellow"}},'v-icon',attrs,false),on),[_vm._v("mdi-crown-outline")])]}}],null,false,2400497158)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("general.wau_you_are_admin")))])]):_vm._e(),_vm._v("\n        "+_vm._s(_vm.$store.state.profile.email)+"\n        "),_c('v-spacer'),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"deep-orange accent-3"},on:{"click":_vm.logout}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-logout-variant")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("general.sign_out")))])])],1),_vm._v(" "),_c('v-main',[_c('router-view')],1),_vm._v(" "),_c('v-footer',{attrs:{"app":"","color":"white"}},[_c('span',[_vm._v("\n            © 2022\n            "),_c('a',{attrs:{"href":"https://vrband.eu","target":"_blank"}},[_vm._v("vr_BAND")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }