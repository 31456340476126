require("./bootstrap");
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/dist/vuetify.min.css";

window.Vue = require("vue");

import Vue from "vue";
import Vuex from "vuex";
import Vuetify from "vuetify";
import VueRouter from "vue-router";
import VueI18n from "vue-i18n";
import Jwt from "jsonwebtoken";

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(Vuetify);

const getDefaultState = () => {
    let token = localStorage.getItem("itb-spa-token");
    let tokenData = token ? Jwt.decode(token) : null;
    return {
        isLoggedIn: false,
        tokenData: tokenData,
        profile: token ? tokenData.user.user : {},
    };
};

const store = new Vuex.Store({
    state: getDefaultState(),
    mutations: {
        authenticate(state, payload) {
            state.isLoggedIn = localStorage.getItem("itb-spa-token")
                ? true
                : false;

            if (state.isLoggedIn) {
                state.profile = payload;
                let token = localStorage.getItem("itb-spa-token");
                state.tokenData = token ? Jwt.decode(token) : null;
            } else {
                state.profile = {};
            }
        },
        resetState(state) {
            state.isLoggedIn = false;
            state.tokenData = null;
            state.profile = {};
        },
    },
    actions: {
        authenticate(context, payload) {
            context.commit("authenticate", payload);
        },
        resetState(context) {
            context.commit("resetState");
        },
    },
});

Vue.component(
    "app-container",
    require("./backend/views/AppContainer.vue").default
);

import Login from "./backend/views/Login.vue";
import ResetPassword from "./backend/views/ResetPassword.vue";
import Error404 from "./backend/views/Error404.vue";
import AdminContainer from "./backend/views/AdminContainer.vue";
import Users from "./backend/views/Users.vue";
import Clients from "./backend/views/Clients.vue";
import OculusDevices from "./backend/views/OculusDevices.vue";
import Trainings from "./backend/views/Trainings.vue";
import Results from "./backend/views/Results.vue";
import Companies from "./backend/views/Companies.vue";
import Divisions from "./backend/views/Divisions.vue";
import Sections from "./backend/views/Sections.vue";

const routes = [
    { path: "/login", name: "login", component: Login },
    {
        path: "/obnovit-heslo",
        name: "reset-password",
        component: ResetPassword,
    },
    {
        path: "/admin",
        component: AdminContainer,
        children: [
            { path: "", redirect: "testy" },
            {
                path: "administratori",
                name: "users",
                component: Users,
                beforeEnter(to, from, next) {
                    const token = localStorage.getItem("itb-spa-token");

                    if (!token) {
                        return;
                    }
                    const tokenData = Jwt.decode(token);

                    if (tokenData.user.user.role === "admin") {
                        next();
                    } else {
                        next("/404");
                    }
                },
            },

            {
                path: "testy",
                name: "trainings",
                component: Trainings,
                beforeEnter(to, from, next) {
                    const token = localStorage.getItem("itb-spa-token");

                    if (!token) {
                        return;
                    }
                    const tokenData = Jwt.decode(token);

                    if (tokenData.user.user.role === "admin") {
                        next();
                    } else {
                        next("/404");
                    }
                },
            },

            {
                path: "vypis-chyb",
                name: "results",
                component: Results,
                beforeEnter(to, from, next) {
                    const token = localStorage.getItem("itb-spa-token");

                    if (!token) {
                        return;
                    }
                    const tokenData = Jwt.decode(token);

                    if (tokenData.user.user.role === "admin") {
                        next();
                    } else {
                        next("/404");
                    }
                },
            },

            {
                path: "ucastnici",
                name: "clients",
                component: Clients,
                beforeEnter(to, from, next) {
                    const token = localStorage.getItem("itb-spa-token");

                    if (!token) {
                        return;
                    }
                    const tokenData = Jwt.decode(token);

                    if (tokenData.user.user.role === "admin") {
                        next();
                    } else {
                        next("/404");
                    }
                },
            },
            {
                path: "bryle-oculus",
                name: "oculus-devices",
                component: OculusDevices,
                beforeEnter(to, from, next) {
                    const token = localStorage.getItem("itb-spa-token");

                    if (!token) {
                        return;
                    }
                    const tokenData = Jwt.decode(token);

                    if (tokenData.user.user.role === "admin") {
                        next();
                    } else {
                        next("/404");
                    }
                },
            },
            {
                path: "firmy",
                name: "companies",
                component: Companies,
                beforeEnter(to, from, next) {
                    const token = localStorage.getItem("itb-spa-token");

                    if (!token) {
                        return;
                    }
                    const tokenData = Jwt.decode(token);

                    if (tokenData.user.user.role === "admin") {
                        next();
                    } else {
                        next("/404");
                    }
                },
            },
            {
                path: "divize",
                name: "divisions",
                component: Divisions,
                beforeEnter(to, from, next) {
                    const token = localStorage.getItem("itb-spa-token");

                    if (!token) {
                        return;
                    }
                    const tokenData = Jwt.decode(token);

                    if (tokenData.user.user.role === "admin") {
                        next();
                    } else {
                        next("/404");
                    }
                },
            },
            {
                path: "oddeleni",
                name: "sections",
                component: Sections,
                beforeEnter(to, from, next) {
                    const token = localStorage.getItem("itb-spa-token");

                    if (!token) {
                        return;
                    }
                    const tokenData = Jwt.decode(token);

                    if (tokenData.user.user.role === "admin") {
                        next();
                    } else {
                        next("/404");
                    }
                },
            },
        ],
        beforeEnter(to, from, next) {
            const token = localStorage.getItem("itb-spa-token");
            if (token != null) {
                next();
            } else {
                next("/login");
            }
        },
    },
    { path: "*", name: "error404", component: Error404 },
];

const router = new VueRouter({
    mode: "history",
    routes,
});

function loadLocalMessages() {
    const locales = require.context(
        "../../src/locales/backend",
        true,
        /[A-za-z0-9-_,\s]+\.json$/i
    );
    const messages = {};

    locales.keys().forEach((key) => {
        const matched = key.match(/([A-za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });

    return messages;
}

const i18n = new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || "cs", //Set locale
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en", //Set fallback locale
    messages: loadLocalMessages(), //Set locale messages
});

const opts = {
    lang: {
        t: (key, ...params) => i18n.t(key, params),
    },
};

export default new Vuetify(opts);

// Directives
Vue.directive("tr-class-on-hover", {
    bind(el, binding) {
        console.log("tr-class-on-hover binded");
        const { value = "" } = binding;
        el.addEventListener("mouseenter", () => {
            el.closest("tr").classList.add("actions-hover-" + value);
        });
        el.addEventListener("mouseleave", () => {
            el.closest("tr").classList.remove("actions-hover-" + value);
        });
    },
});

const app = new Vue({
    el: "#app",
    vuetify: new Vuetify(),
    i18n,
    router,
    store,
});
