<template>
    <v-dialog v-model="show" max-width="700px">
        <v-card>
            <v-card-title>
                <span class="headline" v-if="isEdited">{{
                    $t("clients.edit_client")
                }}</span>
                <span class="headline" v-else>{{
                    $t("clients.add_client")
                }}</span>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-model="client.name"
                                    :label="$t('clients.name')"
                                    autofocus
                                    :rules="rules"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select
                                    :items="companies"
                                    item-text="name"
                                    item-value="id"
                                    v-model="client.company_id"
                                    :label="$t('clients.company_id')"
                                    :rules="rules"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field
                                    v-model="client.internal_id"
                                    :label="$t('clients.internal_id_form')"
                                    :rules="rulesInternalId"
                                    :disabled="client.company_id != 4"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-select
                                    :items="divisions"
                                    item-text="name"
                                    item-value="id"
                                    v-model="client.division_id"
                                    :label="$t('clients.division_id')"
                                    :rules="rulesInternalId"
                                    :disabled="client.company_id != 4"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-select
                                    :items="sections"
                                    item-text="name"
                                    item-value="id"
                                    v-model="client.section_id"
                                    :label="$t('clients.section_id')"
                                    :rules="rulesInternalId"
                                    :disabled="client.company_id != 4"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-model="client.delivery_note"
                                    :label="$t('clients.delivery_note')"
                                    :rows="4"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-model="client.delivery_note_2"
                                    :label="$t('clients.delivery_note_2')"
                                    :rows="4"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="5">
                                <v-switch
                                    color="green"
                                    v-model="client.is_active"
                                    :label="
                                        client.is_active
                                            ? $t('general.active')
                                            : $t('general.not_active')
                                    "
                                ></v-switch>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark @click="submit">{{
                    $t("general.save")
                }}</v-btn>
                <v-btn @click.stop="show = false">{{
                    $t("general.cancel")
                }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ClientForm",
    props: {
        form: {
            type: Object,
        },
        client: Object,
        visible: Boolean,
        isEdited: Boolean,
    },

    data() {
        return {
            companies: [],
            divisions: [],
            sections: [],
            checkedDefault: true,
            valid: true,
            showEye: false,
            rules: [(value) => !!value || this.$t("general.required")],
            rulesInternalId: [
                (value) =>
                    (this.client.company_id == 4 ? !!value : true) ||
                    this.$t("general.required"),
            ],
        };
    },

    watch: {
        show(val) {
            !val && this.$refs.form.reset();
        },
    },
    computed: {
        show: {
            get() {
                return this.visible;
            },
            set(value) {
                if (!value) {
                    this.$emit("close");
                }
            },
        },
    },

    created() {
        this.getCompanies();
        this.getDivisions();
        this.getSections();
    },

    methods: {
        async submit() {
            if (this.$refs.form.validate()) {
                if (this.isEdited) {
                    await axios
                        .put(
                            `/api/updateClient/${this.client.id}`,
                            this.client,
                            {
                                headers: {
                                    Authorization:
                                        "Bearer " +
                                        this.$store.state.tokenData.user
                                            .access_token,
                                },
                            }
                        )
                        .then((response) => {
                            if (response.data.isError) {
                                this.$emit(
                                    "snackMessage",
                                    response.data.message,
                                    "warning"
                                );
                            } else {
                                // response.data.roleName = response.data.role == "admin" ? "Admin": "Uživatel";
                                this.$emit("updateClient", response.data);
                                this.$emit(
                                    "snackMessage",
                                    "clients.client_saved",
                                    "success"
                                );
                                this.$emit("close");
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            this.$emit(
                                "snackMessage",
                                "general.error",
                                "error"
                            );
                        });
                } else {
                    await axios
                        .post(`/api/storeClient`, this.client, {
                            headers: {
                                Authorization:
                                    "Bearer " +
                                    this.$store.state.tokenData.user
                                        .access_token,
                            },
                        })
                        .then((response) => {
                            if (response.data.isError) {
                                this.$emit(
                                    "snackMessage",
                                    response.data.message,
                                    "warning"
                                );
                            } else {
                                this.$emit("addClient", response.data);
                                this.$emit(
                                    "snackMessage",
                                    "clients.client_added",
                                    "success"
                                );
                                this.$emit("close");
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            this.$emit(
                                "snackMessage",
                                "general.error",
                                "error"
                            );
                        });
                }
            }
        },
        async getCompanies() {
            await axios
                .get("/api/companyList", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token,
                    },
                })
                .then((response) => {
                    this.companies = response.data;
                })
                .catch((error) => {
                    console.log(error.response.data);
                });
        },
        async getDivisions() {
            await axios
                .get("/api/divisionList", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token,
                    },
                })
                .then((response) => {
                    this.divisions = response.data;
                })
                .catch((error) => {
                    console.log(error.response.data);
                });
        },
        async getSections() {
            await axios
                .get("/api/sectionList", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token,
                    },
                })
                .then((response) => {
                    this.sections = response.data;
                })
                .catch((error) => {
                    console.log(error.response.data);
                });
        },
    },
};
</script>
