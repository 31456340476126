<template>
    <div>
        <header>
            <v-container>
                <v-row no-gutters>
                    <h1 class="font-weight-light headline">
                        {{ $t("title.sections") }}
                    </h1>
                    <v-spacer></v-spacer>
                    <v-lazy v-if="isSectionsLoaded" transition="fade-transition">
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            :label="searchLabel"
                            single-line
                            dense
                            hide-details
                            style="max-width: 200px"
                            class="mr-5 pr-5"
                        ></v-text-field>
                    </v-lazy>
                    <v-lazy transition="fade-transition">
                        <v-btn
                            color="success"
                            dark
                            small
                            @click.stop="
                                dialog.sectionForm = true;
                                isSectionEdited = false;
                            "
                            >{{ $t("general.add") }}</v-btn
                        >
                    </v-lazy>
                </v-row>
            </v-container>
        </header>
        <section class="py-7">
            <v-container>
                <v-row v-if="isSectionsLoaded && !sections.length">
                    <v-col cols="12" md="4" class="offset-md-4">
                        <v-alert
                            icon="mdi-information-outline"
                            type="info"
                            text
                            border="top"
                        >
                            {{ $t("general.we_dont_have_data_yet") }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-sheet class="px-3 pt-3 pb-3" v-if="!isSectionsLoaded">
                    <v-skeleton-loader
                        class="mx-auto"
                        type="table"
                    ></v-skeleton-loader>
                </v-sheet>
                <v-card v-else>
                    <v-data-table
                        v-if="sections.length"
                        :headers="headers"
                        :items="sections"
                        :search="search"
                    >
                        <template v-slot:item.action="{ item }">
                            <div style="display: flex">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            v-tr-class-on-hover="'blue'"
                                            size="20px"
                                            color="blue"
                                            class="mr-2"
                                            @click="editUser(item)"
                                            v-on="on"
                                            >mdi-pencil-outline</v-icon
                                        >
                                    </template>
                                    <span>{{ $t("general.edit") }}</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            size="22px"
                                            color="red"
                                            v-tr-class-on-hover="'red'"
                                            class="mr-2"
                                            @click="confirmDelete(item)"
                                            v-on="on"
                                            >mdi-delete-outline</v-icon
                                        >
                                    </template>
                                    <span>{{ $t("general.delete") }}</span>
                                </v-tooltip>
                            </div>
                        </template>
                        <!-- <template v-slot:item.is_active="{ item }">
                            <v-switch
                                class="mt-0 pt-0 justify-content-center"
                                hide-details
                                color="green"
                                v-model="item.is_active"
                                @change="changeStatus(item)"
                            ></v-switch>
                        </template> -->
                    </v-data-table>
                </v-card>
                <SectionForm
                    :section="selectedSection"
                    :visible="dialog.sectionForm"
                    :isEdited="isSectionEdited"
                    @addSection="addSection"
                    @updateSection="updateSection"
                    @close="dialog.sectionForm = false"
                    @snackMessage="snackMessage"
                />

                <ConfirmationDialog
                    :name="selectedSection.name"
                    :text="'sections.delete_confirmation'"
                    v-if="dialog.confirmation"
                    @confirm="deleteSection"
                    @cancel="cancelDelete"
                />

                <SnackMessage ref="SnackMessage" />
            </v-container>
        </section>
    </div>
</template>

<script>
import SectionForm from "../components/SectionForm";
import ConfirmationDialog from "../components/ConfirmationDialog";
import SnackMessage from "../components/SnackMessage";

export default {
    data() {
        return {
            sections: [],
            isSectionsLoaded: false,
            search: "",
            dialog: {
                confirmation: false,
                sectionForm: false,
            },
            section: {
                name: null,
                is_active: null
            },
            selectedSection: {
                name: null,
                is_active: null
            },
            defaultSection: {
                name: null,
                is_active: null
            },
            isSectionEdited: false,
        };
    },

    components: {
        SectionForm,
        ConfirmationDialog,
        SnackMessage,
    },

    computed: {
        headers() {
            return [
                {
                    text: this.$t("general.actions"),
                    value: "action",
                    sortable: false,
                },
                {
                    text: this.$t("sections.name"),
                    align: "left",
                    sortable: false,
                    value: "name",
                },
                // {
                //     text: this.$t("sections.is_active"),
                //     align: "left",
                //     sortable: false,
                //     value: "is_active",
                // },
                {
                    text: this.$t("general.created_at"),
                    align: "left",
                    sortable: false,
                    value: "created_at",
                },
            ];
        },
        searchLabel() {
            return this.$t("general.search");
        },
    },

    created() {
        this.getSections();
    },

    methods: {
        snackMessage(msg, type) {
            this.$refs.SnackMessage.showMessage(msg, type);
        },

        async getSections() {
            await axios
                .get("/api/sectionList", {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token,
                    },
                })
                .then((response) => {
                    this.sections = response.data;
                    this.isSectionsLoaded = true;
                })
                .catch((error) => {
                    console.log(error.response.data);
                });
        },
        addSection(section) {
            this.sections.push(section);
            this.getSections()
        },
        editUser(item) {
            this.selectedSection = Object.assign({}, item);
            this.dialog.sectionForm = true;
            this.isSectionEdited = true;
        },
        updateSection(section) {
            let index = this.sections.findIndex((item) => item.id == section.id);
            this.sections.splice(index, 1, section);
            this.getSections()
        },
        confirmDelete(item) {
            this.selectedSection = item;
            this.dialog.confirmation = true;
        },
        cancelDelete() {
            this.dialog.confirmation = false;
        },
        async deleteSection() {
            this.dialog.confirmation = false;
            let index = this.sections.indexOf(this.selectedSection);

            await axios
                .delete(`/api/deleteSection/${this.selectedSection.id}`, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            this.$store.state.tokenData.user.access_token,
                    },
                })
                .then((response) => {
                    this.selectedSection = this.defaultSection;
                    this.sections.splice(index, 1);

                    this.$refs.SnackMessage.showMessage(
                        "sections.deleted_successfully",
                        "success"
                    );
                })
                .catch((error) => {
                    console.log(error);
                    this.$refs.SnackMessage.showMessage(
                        "general.error",
                        "error"
                    );
                });
        },
        async changeStatus(item) {
            try {
                const request = await axios.put(
                    "/api/updateStatusSection/" + item.id,
                    item,
                    {
                        headers: {
                            Authorization:
                                "Bearer " +
                                this.$store.state.tokenData.user.access_token,
                        },
                    }
                );
                this.getSections()
                this.snackMessage("general.item_saved", "success");
            } catch (error) {
                console.error(error);
            }
        },
    },
};
</script>
