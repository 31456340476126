var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',[_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('h1',{staticClass:"font-weight-light headline"},[_vm._v("\n                    "+_vm._s(_vm.$t("title.divisions"))+"\n                ")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),(_vm.isDivisionsLoaded)?_c('v-lazy',{attrs:{"transition":"fade-transition"}},[_c('v-text-field',{staticClass:"mr-5 pr-5",staticStyle:{"max-width":"200px"},attrs:{"append-icon":"mdi-magnify","label":_vm.searchLabel,"single-line":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),_vm._v(" "),_c('v-lazy',{attrs:{"transition":"fade-transition"}},[_c('v-btn',{attrs:{"color":"success","dark":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.dialog.divisionForm = true;
                            _vm.isDivisionEdited = false;}}},[_vm._v(_vm._s(_vm.$t("general.add")))])],1)],1)],1)],1),_vm._v(" "),_c('section',{staticClass:"py-7"},[_c('v-container',[(_vm.isDivisionsLoaded && !_vm.divisions.length)?_c('v-row',[_c('v-col',{staticClass:"offset-md-4",attrs:{"cols":"12","md":"4"}},[_c('v-alert',{attrs:{"icon":"mdi-information-outline","type":"info","text":"","border":"top"}},[_vm._v("\n                        "+_vm._s(_vm.$t("general.we_dont_have_data_yet"))+"\n                    ")])],1)],1):_vm._e(),_vm._v(" "),(!_vm.isDivisionsLoaded)?_c('v-sheet',{staticClass:"px-3 pt-3 pb-3"},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"table"}})],1):_c('v-card',[(_vm.divisions.length)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.divisions,"search":_vm.search},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"tr-class-on-hover",rawName:"v-tr-class-on-hover",value:('blue'),expression:"'blue'"}],staticClass:"mr-2",attrs:{"size":"20px","color":"blue"},on:{"click":function($event){return _vm.editUser(item)}}},on),[_vm._v("mdi-pencil-outline")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("general.edit")))])]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"tr-class-on-hover",rawName:"v-tr-class-on-hover",value:('red'),expression:"'red'"}],staticClass:"mr-2",attrs:{"size":"22px","color":"red"},on:{"click":function($event){return _vm.confirmDelete(item)}}},on),[_vm._v("mdi-delete-outline")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("general.delete")))])])],1)]}}],null,false,342761776)}):_vm._e()],1),_vm._v(" "),_c('DivisionForm',{attrs:{"division":_vm.selectedDivision,"visible":_vm.dialog.divisionForm,"isEdited":_vm.isDivisionEdited},on:{"addDivision":_vm.addDivision,"updateDivision":_vm.updateDivision,"close":function($event){_vm.dialog.divisionForm = false},"snackMessage":_vm.snackMessage}}),_vm._v(" "),(_vm.dialog.confirmation)?_c('ConfirmationDialog',{attrs:{"name":_vm.selectedDivision.name,"text":'divisions.delete_confirmation'},on:{"confirm":_vm.deleteDivision,"cancel":_vm.cancelDelete}}):_vm._e(),_vm._v(" "),_c('SnackMessage',{ref:"SnackMessage"})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }