var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',[_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('h1',{staticClass:"font-weight-light headline"},[_vm._v(_vm._s(_vm.$t("title.users")))]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),(_vm.isUsersLoaded)?_c('v-lazy',{attrs:{"transition":"fade-transition"}},[_c('v-text-field',{staticClass:"mr-5 pr-5",staticStyle:{"max-width":"200px"},attrs:{"append-icon":"mdi-magnify","label":_vm.searchLabel,"single-line":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),_vm._v(" "),(_vm.isUsersLoaded)?_c('v-lazy',{attrs:{"transition":"fade-transition"}},[_c('v-btn',{attrs:{"color":"success","dark":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.dialog.userForm=true; _vm.isUserEdited=false}}},[_vm._v(_vm._s(_vm.$t("general.add")))])],1):_vm._e()],1)],1)],1),_vm._v(" "),_c('section',{staticClass:"py-7"},[_c('v-container',[(!_vm.users.length)?_c('v-sheet',{staticClass:"px-3 pt-3 pb-3"},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"table"}})],1):_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"search":_vm.search},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"tr-class-on-hover",rawName:"v-tr-class-on-hover",value:('blue'),expression:"'blue'"}],staticClass:"mr-2",attrs:{"size":"20px","color":"blue"},on:{"click":function($event){return _vm.editUser(item)}}},on),[_vm._v("mdi-pencil-outline")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("general.edit")))])]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"tr-class-on-hover",rawName:"v-tr-class-on-hover",value:('red'),expression:"'red'"}],staticClass:"mr-2",attrs:{"size":"22px","color":"red"},on:{"click":function($event){return _vm.confirmDelete(item)}}},on),[_vm._v("mdi-delete-outline")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("general.delete")))])])],1)]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getUserRoleNameByKey(item.role)))])]}}])})],1),_vm._v(" "),_c('UserForm',{attrs:{"user":_vm.selectedUser,"userRoles":_vm.userRoles,"visible":_vm.dialog.userForm,"isEdited":_vm.isUserEdited},on:{"addUser":_vm.addUser,"updateUser":_vm.updateUser,"close":function($event){_vm.dialog.userForm=false},"snackMessage":_vm.snackMessage}}),_vm._v(" "),(_vm.dialog.confirmation)?_c('ConfirmationDialog',{attrs:{"name":_vm.selectedUser.name,"text":'users.delete_confirmation'},on:{"confirm":_vm.deleteUser,"cancel":_vm.cancelDelete}}):_vm._e(),_vm._v(" "),_c('SnackMessage',{ref:"SnackMessage"})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }